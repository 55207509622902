<template>
  <div class="footer">
    <div class="contact">
      <h4>Any questions?</h4>
      <p>Contact: Anna.kohout@outlook.com</p>
    </div>
    <div class="copyright">© Anna Banana Bakery</div>
    <div class="self-plug">
      <a href="https://mattwf.dev" target="_blank">Created by Mattwf</a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  width: 100%;
  height: fit-content;
  background-color: var(--background);
  border-top: 1px solid var(--accent);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact p {
  margin: 0px;
  font-size: 1rem;
}
.copyright {
  font-size: 0.8rem;
  margin: 0px;
}
h4 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  margin-top: 20px;
}

.self-plug {
  font-style: italic;
  margin-bottom: 10px;
  margin-top: 20px;
  a {
    text-decoration: none;
    color: var(--primary);
  }
}
@media (max-width: 768px) {
  h4 {
    font-size: 1.2rem;
  }
}
</style>
